import * as React from 'react';
import { LandingState, LandingStateAction } from '../../logic/state/LandingState';
import CustomAppBar from '../common/Menu/CustomAppBar';
import HeroSection from './Hero';
import AboutSection from './aboutSection';
import OurWork from './ourWork';
import FaqSection from './faqSection';
import ContactSection from './contactSection';
import Footer from '../common/footer';

interface IHomepageProps {
    state: LandingState;
    dispatch: React.Dispatch<LandingStateAction>;
}

const Homepage: React.FC<IHomepageProps> = ({ state, dispatch }) => {
    return (  
        <>
            <CustomAppBar state={state} dispatch={dispatch} />
            <HeroSection state={state} dispatch={dispatch} />
            <div id='about'><AboutSection /></div>
            <div id='work'><OurWork /></div>
            <div id='faq'><FaqSection /></div>
            <div id='contact'><ContactSection /></div>
            <Footer />
        </>
    );
}
 
export default Homepage;
