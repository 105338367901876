import { ThemeOptions } from '@mui/material/styles';

export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#f48ca0',
      dark: '#a10f18',
      light: '#f2d7d7',
    },
    secondary: {
      main: '#59766c',
    },
    background: {
      paper: '#fdf7f8',
    },
  },
  typography: {
    h1: {
      fontFamily: 'AmsterdamOne',
    },
    h2: {
      fontFamily: 'AmsterdamOne',
    },
    h3: {
      fontFamily: 'AmsterdamOne',
    },
    h4: {
      fontFamily: 'AmsterdamOne',
    },
    h5: {
      fontFamily: 'AmsterdamOne',
    },
    h6: {
      fontFamily: 'AmsterdamOne',
    },
  },
};

export const darkThemeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#f48ca0',
      dark: '#a10f18',
      light: '#f2d7d7',
    },
    secondary: {
      main: '#59766c',
    },
  },
  typography: {
    h1: {
      fontFamily: 'AmsterdamOne',
    },
    h2: {
      fontFamily: 'AmsterdamOne',
    },
    h3: {
      fontFamily: 'AmsterdamOne',
    },
    h4: {
      fontFamily: 'AmsterdamOne',
    },
    h5: {
      fontFamily: 'AmsterdamOne',
    },
    h6: {
      fontFamily: 'AmsterdamOne',
    },
  },
};