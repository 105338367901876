import { Autocomplete, Avatar, Box, Button, TextField, Typography } from '@mui/material';
import * as React from 'react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import GoogleMaps from '../common/AddressSearch';

const Signup: React.FC = () => {
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {

    };

    return (  
        <React.Fragment>
            <Box sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center'}} >
                <Avatar sx={{ m: 1, bgcolor: 'primary' }}>
                    <LockOutlinedIcon />
                </Avatar>

                <Typography component='h1' variant='h5'>
                    Create account
                </Typography>

                <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <TextField
                            sx={{ mr: 1.5 }}
                            margin='normal'
                            required
                            fullWidth
                            id='first_name'
                            label='First Name'
                            name='first_name'
                            autoComplete='first_name'
                            autoFocus
                        />
                        <TextField
                            sx={{ ml: 1.5 }}
                            margin='normal'
                            required
                            fullWidth
                            id='last_name'
                            label='Last Name'
                            name='last_name'
                            autoComplete='last_name'
                        />
                    </Box>

                    <TextField
                        margin='normal'
                        required
                        fullWidth
                        id='email'
                        label='Email Address'
                        name='email'
                        autoComplete='email'
                    />
                    <TextField
                        margin='normal'
                        required
                        fullWidth
                        id='password'
                        label='Password'
                        name='password'
                        autoComplete='current_password'
                        type='password'
                    />
                    
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            sx={{ width: '100%', mt: 2, mb: 1 }}
                            label='Birthday'
                            openTo='year'
                            views={['year', 'month', 'day']}
                        />
                    </LocalizationProvider>
                    <GoogleMaps />

                    <Button 
                        type='submit'
                        fullWidth
                        variant='contained'
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign up
                    </Button>
                </Box>
            </Box>
        </React.Fragment>
    );
}
 
export default Signup;