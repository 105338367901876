import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom'
import Homepage from './components/landing/homepage';
import Dashboard from './components/dashboard/dashboard';
import Signup from './components/login/signup';
import { CssBaseline, PaletteMode, ThemeProvider, createTheme } from '@mui/material';
import { darkThemeOptions, themeOptions } from './styles/theme';
import { InitialLandingState, LandingStateReducer } from './logic/state/LandingState';
import { SnackbarProvider } from 'notistack';
import { SnackbarConfigurator } from './components/common/snackbarConfig';

function App() {
  const [state, dispatch] = React.useReducer(LandingStateReducer, InitialLandingState);
  const { mode } = state;
  const defaultTheme = createTheme(mode === 'dark' ? darkThemeOptions : themeOptions);

  // React.useEffect(() => {
  //   const mq = window.matchMedia('(prefers-color-scheme: dark)');
  //   if (mq.matches) dispatch({ type: 'SET_COLOR_MODE', payload: 'dark' as PaletteMode });
  //   mq.addEventListener("change", (evt) => dispatch({ type: 'SET_COLOR_MODE', payload: mq.matches ? 'dark' : 'light' }));
  // }, []);

  return (
    <React.Fragment>
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <SnackbarProvider maxSnack={3}>
          <SnackbarConfigurator />
          <Routes>
            <Route index element={<Homepage state={state} dispatch={dispatch} />} />
            <Route path='/register' element={<Signup />} />
            <Route path='/dashboard' element={<Dashboard />} />
          </Routes>
        </SnackbarProvider>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
