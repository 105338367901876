export const faqs = [
    {
        question: 'How do I prepare my quilt top for long arm quilting?',
        answer: 'To prepare your quilt top, ensure it is clean, pressed, and free of loose threads. The backing fabric should be at least 4 inches larger than the quilt top on all sides. Please do not baste or pin the layers together, as we will do that for you.'
    },
    {
        question: 'What types of quilting patterns do you offer?',
        answer: 'We offer a wide variety of quilting patterns, from traditional to contemporary designs. Our team will work with you to choose the perfect pattern that complements your quilt top and meets your vision.'
    },
    {
        question: 'How long does it take to complete a quilt?',
        answer: 'The turnaround time for quilting services varies depending on the complexity of the design and our current workload. Typically, it takes about 2-4 weeks to complete a quilt. We will provide you with an estimated timeline when you place your order.'
    },
    {
        question: 'How much does long arm quilting cost?',
        answer: 'The cost of long arm quilting services depends on the size of your quilt and the complexity of the quilting pattern. Please contact us for a detailed pricing guide and to discuss your specific project.'
    }
];