export const imageData = [
    {
        src: '/images/masonryGrid/pink_folded.webp',
        alt: 'Quilting Machine'
    },
    {
        src: '/images/masonryGrid/window_board.webp',
        alt: 'Quilting Machine'
    },
    {
        src: '/images/masonryGrid/christmas_angle.webp',
        alt: 'Quilting Machine'
    },
    {
        src: '/images/masonryGrid/brex_full.webp',
        alt: 'Quilting Machine'
    },
    {
        src: '/images/masonryGrid/demi_flower.webp',
        alt: 'Quilting Machine'
    },
    {
        src: '/images/masonryGrid/feather.webp',
        alt: 'Quilting Machine'
    },
    {
        src: '/images/masonryGrid/kite.webp',
        alt: 'Quilting Machine'
    },
    {
        src: '/images/masonryGrid/studio.webp',
        alt: 'Quilting Machine'
    },
    {
        src: '/images/masonryGrid/window.webp',
        alt: 'Quilting Machine'
    },
    {
        src: '/images/masonryGrid/brex_angle.webp',
        alt: 'Quilting Machine'
    },
    {
        src: '/images/masonryGrid/christmas_close.webp',
        alt: 'Quilting Machine'
    },
    {
        src: '/images/masonryGrid/leaves.webp',
        alt: 'Quilting Machine'
    },
    {
        src: '/images/masonryGrid/brex_table.webp',
        alt: 'Quilting Machine'
    }
];