export interface QuiltSize {
    size: string;
    width: number;
    length: number;
}

export const quiltSizes: QuiltSize[] = [
    {
        size: 'Baby',
        width: 36,
        length: 36
    },
    {
        size: 'Crib',
        width: 45,
        length: 60
    },
    {
        size: 'Throw',
        width: 60,
        length: 60
    },
    {
        size: 'Twin',
        width: 72,
        length: 90
    },
    {
        size: 'Full',
        width: 84,
        length: 90
    },
    {
        size: 'Queen',
        width: 90,
        length: 100
    },
    {
        size: 'King',
        width: 108,
        length: 100
    }
];