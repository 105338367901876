import { EmailRequest } from "../interfaces/email-request";
import getHttpService from "./httpService";

const httpService = getHttpService();

const sendEmail = async (email: EmailRequest) => {
    const response = await httpService.httpService.post("Email/contact", email);
}

const emailService = {
    sendEmail
};

export default emailService;