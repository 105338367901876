import { PaletteMode } from "@mui/material";

export interface LandingState {
    mode: PaletteMode;
    open: boolean;
}

export const InitialLandingState: LandingState = {
    mode: 'light' as PaletteMode,
    open: false
}

interface ILandingSetColorMode {
    type: 'SET_COLOR_MODE';
    payload: PaletteMode;
}

interface ILandingSetDrawerOpen {
    type: 'SET_DRAWER_OPEN';
    payload: boolean;
}

export type LandingStateAction = 
    | ILandingSetColorMode
    | ILandingSetDrawerOpen;

export const LandingStateReducer = (state: LandingState, action: LandingStateAction) => {
    switch (action.type) {
        case 'SET_COLOR_MODE':
            return { ...state, mode: action.payload };
        case 'SET_DRAWER_OPEN': 
            return { ...state, open: action.payload };
        default: 
            return state;
    }
}