import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import * as React from 'react';
import { faqs } from './faqs';
import { ExpandMore } from '@mui/icons-material';

const FaqSection: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [expanded, setExpanded] = React.useState<number | false>(false);

    const handleChange = (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (  
        <React.Fragment>
            <Container maxWidth='lg' sx={{ pt: 5, mt: 10 }}>
                <Box>
                    <Typography variant={isMobile ? 'h4' : 'h2'} component='h2' gutterBottom sx={{ mb: 10 }}>
                        Frequently Asked Questions
                    </Typography>
                    {faqs.map((faq, index) => (
                        <Accordion key={index} expanded={expanded === index} onChange={handleChange(index)}>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <Typography>{faq.question}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>{faq.answer}</Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>
            </Container>
        </React.Fragment>
    );
}
 
export default FaqSection;